import React, { Component } from 'react';
import * as actions from '../actions';
import { connect } from 'react-redux';
import YouTube from 'react-youtube';


class Public extends Component {

  state = {
    videoId: null,
    v_password: "",    
  };

  componentDidMount() {
    const videoId = this.props.match.params.key;
    this.setState({ videoId: videoId });
    if (!videoId) return false;
  }

  validateAndFetch = (e) => {
    e.preventDefault();

    const { v_password } = this.state;
    const { validate } = this.props;

    if (v_password) {
      console.log("calling ");
      validate(this.state.videoId, v_password)
    }
  }


  onInputChange = (e) => {
    this.setState({v_password: e.target.value })
  }

  render() {
    const { video } = this.props;
    if(video.v_title)  return this.renderVideo() 
    return this.renderLogin() 
  }

  renderLogin = () => {
    const { v_password } = this.state;
    const { isValidating, validateError } = this.props;
    return (


      <div className="form-wrapper public-form">
      <form className="form-signin" onSubmit={this.validateAndFetch} >
        <div className="image">
          <img src={'/image.png'}   alt="" className="mb-10"/>              
        </div>
        
        <h4 className="h4 mb-3 font-weight-normal">Enter Video Password</h4>
       
        {validateError ? (
              <div className="alert alert-danger" >
              {validateError}
            </div>
            ) : (<span></span>)
          } 
          

        <label htmlFor="inputPassword" className="sr-only">Password</label>
        <input type="password" id="inputPassword" name="v_password" value={v_password} onChange={this.onInputChange}  className="form-control" placeholder="Password" required />
       
       <button className="btn btn-lg btn-primary btn-block" type="submit" disabled={isValidating} >Enter</button>
    </form>
    </div>


    
    )
  }

  renderVideo = ()=> {

    const { v_title, v_description, v_youtube } = this.props.video
    let videoId = null;

    if (v_youtube) {
      videoId = v_youtube.split('?v=')[1];
    }
    const opts = {
      height: '360',
      width: '100%',
      playerVars: {
        autoplay: 0
      }
    };
    return (
      <>
      <main role="main" className="public-main flex-shrink-0" >
        <div className="container">
          <div className="logo-wrapper text-center">
            <img src={'/image.png'}   alt="wwen"/> 
          </div>
          <div className="video-wrapper">
          <YouTube
            videoId={videoId}
            opts={opts}
            onReady={this._onReady}
          />
          </div>
          <h1>{v_title}</h1>
          <p className="lead">{v_description}</p>
        </div>
      </main>

      <footer className="footer mt-auto py-3">
        <div className="container">
          <span className="text-muted">Creative writing support for school students &amp; teachers</span>
          <br />
          <a href="https://emilyandnova.weebly.com/">emilyandnova.weebly.com</a>
          <span className="yellowbox"></span>
        </div>
      </footer>
      </>     
    );
  }
}

const mapStateToProps = ({ data }) => {
  return {
    video: data.video,
    validateError: data.validateError,
    isValidating: data.isValidating
  }
}

export default connect(mapStateToProps, actions)(Public);