
import { 
  FETCH_VIDEOS,
  GET_VIDEO,
  AUTH_SUCCESS, 
  LOGOUT, 
  AUTH_ERROR, 
  VALIDATE_ERROR, 
  VALIDATE_INIT,
  SAVE_ERROR,
  SAVE_INIT
} from './types';

import axios from '../axios'
import { history } from '../_helpers/history';

export const addVideo = newVideo => async dispatch => {
  dispatch({
    type: SAVE_INIT,
  });
  axios
    .post(`/api/video`, newVideo )
    .then((response) => {
    dispatch(fetchVideos())
  })
  .catch((error) => {
    console.log("Validate Errorororor")
    dispatch({
      type: SAVE_ERROR,
      payload: {message:"Invalid request"}
    });
  });
};

export const updateVideo = (id, updateVideo) => async dispatch => {
  dispatch({
    type: SAVE_INIT,
  });
  axios
    .put(`/api/video/${id}`, updateVideo )
    .then((response) => {
    dispatch(fetchVideos())
  })
  .catch((error) => {
    console.log("Validate Errorororor", error)
    dispatch({
      type: SAVE_ERROR,
      payload: {message:"Invalid request"}
    });
  });
};


export const logout = () => async dispatch => {
   localStorage.removeItem('user_token')
   dispatch({
    type: LOGOUT,
  });
  history.push('/signin')
}

export const login = (email, pw) =>  async dispatch => {
    axios
      .post('/api/auth/login', {email, password: pw})
      .then((response) => {
      console.log('Response :', response);
      const {userData} = response.data;
      console.log(userData.user_token)
      localStorage.setItem('user_token', userData.user_token);
      dispatch({
        type: AUTH_SUCCESS,
        payload: {uid: userData.user_token, isLoggedIn: true}
      });
      history.push('/home')
  })
  .catch((error) => {
    dispatch({
      type: AUTH_ERROR,
      payload: {message:"Invalid credentials"}
    });
  });
}

export const fetchVideos = () => async dispatch => {
  axios
    .get('/api/video')
    .then((response) => {
    console.log('Response :', response);
    const { videos } = response.data;
    dispatch({
      type: FETCH_VIDEOS,
      payload: { videos }
    });
  })
  .catch((error) => {
  dispatch({
    type: AUTH_ERROR,
    payload: {message:"Invalid credentials"}
  });
  });
};


export const validate = (id, v_password) =>  async dispatch => {
  dispatch({
    type: VALIDATE_INIT,
  });
  axios
    .post(`/api/video/${id}/validate`, { v_password })
    .then((response) => {

    dispatch({
      type: GET_VIDEO,
      payload: {video: response.data.video}
    });
})
.catch((error) => {
  console.log("Validate Errorororor")
  dispatch({
    type: VALIDATE_ERROR,
    payload: {message:"Invalid password"}
  });
});
}