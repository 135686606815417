import {
  FETCH_VIDEOS,
  GET_VIDEO,
  AUTH_SUCCESS,
  LOGOUT,
  AUTH_ERROR,
  VALIDATE_ERROR,
  VALIDATE_INIT,
  SAVE_ERROR,
  SAVE_INIT
} from '../actions/types';

const _STATE = {
  isLoggedIn: false,
  uid: '',
  video : {
    v_title:null,
    v_youtube : null,
    v_password:null,
    v_description: null
  },
  data : null,
  videos: null,
  loginError : null,
  validateError: null,
  isValidating: false,
  isSaving: false,
  saveError: null
};
export default (state = _STATE, action) => {
  switch(action.type) {
    case FETCH_VIDEOS:
      return {...state, videos : action.payload.videos, isSaving: false }     
    case GET_VIDEO:
        return {...state, video : action.payload.video, isValidating: false, validateError: null  }
    case VALIDATE_ERROR:
        return {...state, validateError: action.payload.message, isValidating: false }
    case VALIDATE_INIT:
        return {...state, isValidating: true, validateError: null }
    case SAVE_ERROR:
        return {...state, saveError: action.payload.message, isSaving: false }
    case SAVE_INIT:
        return {...state, isSaving: true, saveError: null }
    case AUTH_SUCCESS:
        return {...state, ...action.payload , loginError: null}
    case LOGOUT:
        return {...state, isLoggedIn: false, uid: "" }
    case AUTH_ERROR:
        return {...state, loginError: action.payload.message}
    default:
      return state;
  }
};