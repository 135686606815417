import React, {Component} from 'react';
import {connect} from 'react-redux';
import {login} from '../actions';

class Signin extends Component {

  state = {
    email: '',
    password: '',
  }

  onSignIn = (e) => {
    e.preventDefault();
    const {login} = this.props;
    const {email, password} = this.state;
    login(email, password)
  }

  onInputChange = (e) => {
   this.setState({[e.target.name]: e.target.value})
  }

  render() {
    const {email, password} = this.state;
    return (    
      <div className="form-wrapper">
      <form className="form-signin" onSubmit={this.onSignIn}>

        <h1 className="h3 mb-3 font-weight-normal">Please sign in</h1>
 
        {this.props.loginError ? (
            <div className="alert alert-danger" >
            {this.props.loginError}
          </div>
          ) : (<span></span>)
        }
          

        <label for="inputEmail" className="sr-only">Email address</label>
        <input type="email" id="inputEmail" name="email" value={email} onChange={this.onInputChange}  className="form-control" placeholder="Email address" required />
        <label for="inputPassword" class="sr-only">Password</label>
        <input type="password" id="inputPassword" name="password" value={password} onChange={this.onInputChange} class="form-control" placeholder="Password" required />
       
       <button className="btn btn-lg btn-primary btn-block" type="submit">Sign in</button>
    </form>
    </div>
   
    );
  }
}

const mapStateToProps = ({data}) => {
  return {  
    loginError:data.loginError
  }
}

export default connect(mapStateToProps, {login})(Signin);