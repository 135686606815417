
import React, {Component} from 'react';
import { Route, Router, Link, Redirect, Switch } from 'react-router-dom'

// import Folio from './Folio/Folio';
import { history } from './_helpers/history';
import Home from './components/Home';
import Public from './components/Public';
import Signin from './components/Signin';
import PrivateRoute from './PrivateRoute';
import "./components/style.css";

class App extends Component {  
  render() {
    return (
      <Router history={history}>
        <>
              <Switch>
                <Redirect exact from='/' to='/home' />
                <PrivateRoute path='/home' component={Home} />
                <Route  path='/signin' component={Signin} />                
                <Route path="/public/:key" component={Public} /> 
                <Route render={() => <h5>Not Found</h5>} />
              </Switch>
        </>
      </Router>
    );
  }
}


export default App;
