import axios from 'axios';

axios.defaults.baseURL = 'https://enmoapi.herokuapp.com/';
// axios.defaults.baseURL = 'http://localhost:3000/';

axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('user_token');
    if (token) {
      const item = { ...config };
      item.headers.Authorization = `Bearer ${token}`;
      return item;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

export default axios;