import React, {Component} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import * as actions from '../actions';
import ListItem from './ListItem';
import {Modal, Button} from 'react-bootstrap'

class List extends Component {
  state = {
    showForm: false,   
    currentId: null,
    formValue: {    
      v_title: "",
      v_description : "",
      v_youtube: "",
      v_password: ""
    }
  };


  componentDidMount() {
    this.props.fetchVideos();
  }

  inputChange = e => {
    console.log(e.target.name)
    var formValue = this.state.formValue;
    formValue[e.target.name] = e.target.value
    this.setState({formValue}, ()=>{
      console.log(this.state.formValue)
    });
  };

  handleSubmit = e => {

    const {formValue, currentId } = this.state;
    const {addVideo, updateVideo} = this.props;

    e.preventDefault();
    
    if(!formValue.v_title || !formValue.v_description || !formValue.v_youtube || !formValue.v_password) {
      this.setState({formError : "All fields are required"})
      return;
    }
    
    console.log("currentId :::", currentId)
    
    if(currentId){
      updateVideo(currentId, {
        v_title: formValue.v_title,
        v_description: formValue.v_description,
        v_youtube: formValue.v_youtube,
        v_password: formValue.v_password
      } );
    }else{
      addVideo({
        v_title: formValue.v_title,
        v_description: formValue.v_description,
        v_youtube: formValue.v_youtube,
        v_password: formValue.v_password
      });
    }
    
    this.handleModal(false);
    
  };

  handleModal = (val, item, id) =>{
    console.log("Item : ", item, id)

    if(id){      
      this.setState({formValue: item, currentId: id});
    }else{
      this.setState({formValue: {    
        v_title: "",
        v_description : "",
        v_youtube: "",
        v_password: ""
      },
       currentId: null});
    }

    this.setState({showForm: val});
  }
  renderForm = () => {
    const {showForm, formValue, currentId, formError} = this.state;
    const {saveError, isSaving } = this.props; 
    if (showForm) {
      return (
        <div className="col s10 offset-s1"> 
        <Modal show={showForm} >
        <form className="form">
          <Modal.Header >
          <Modal.Title>
            {currentId?( "Edit"):("New")} Video
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {saveError ? (
              <div className="alert alert-danger" >
              {saveError}
            </div>
            ) : (<span></span>)
          }
            <div className="form-group">
              <label htmlFor="youtubeURL">Youtube URL</label>
              <input type="text"  value={formValue.v_youtube} onChange={this.inputChange} placeholder="https://www.youtube.com/watch?v=AC5J-nfYALA" name="v_youtube" className="form-control" id="youtubeURL" required />
            </div>
            <div className="form-group">
              <label htmlFor="videoTitle">Video Title</label>
              <input type="text"  value={formValue.v_title} onChange={this.inputChange} name="v_title" className="form-control" id="videoTitle" required />
            </div>
            <div className="form-group">
              <label htmlFor="videoDescription">Video Description</label>
              <textarea  value={formValue.v_description} onChange={this.inputChange} name="v_description" className="form-control" id="videoDescription" required />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input type="text"  value={formValue.v_password} onChange={this.inputChange} name="v_password" className="form-control" id="password" required />
            </div>
           
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={()=> {this.handleModal(false)}}> Close </Button>
           { currentId? (
              <Button  variant="primary" onClick={this.handleSubmit} disabled={isSaving}> Update Changes </Button>
            ):(
              <Button  variant="success" onClick={this.handleSubmit} disabled={isSaving}> Save Changes </Button>
            )
            }
          
          </Modal.Footer>
          </form>
          </Modal>   
        </div>
      );
    }
  };
  renderVideo() {
    const { videos } = this.props;
    const _videos = _.map(videos, (value, key) => {
      return <ListItem open={this.handleModal}  key={value._id} id={key+1} video={value} />;
    });

    if (!_.isEmpty(_videos)) {
      return (
        <div className="table-responsive">
        <table className="table table-striped table-sm">
          <thead>
            <tr>
              <th>#</th>
              <th>Video Name</th>  
              <th>Youtube URL</th>              
              <th>Public URL</th>
              <th>Video Password</th>
              <th></th>             
            </tr>
          </thead>
          <tbody>
          {_videos}
          </tbody>
        </table>
      </div>
      )
      ;
    }
    return (
      <div className="center-align m-t-10">
        <span>Loading...</span>
      </div>
    );
  }

  render() {
    const {showForm} = this.state;
    return (
      <div className="video-list-container">        
        <div className="row">
        {showForm ? (
          //  <a className="btn btn-primary" onClick={() => this.setState({showForm: !showForm})} >cancel</a>
           <span></span>
            ) : (
            <a className="btn btn-primary" onClick={()=> {this.handleModal(true)}}> New Video</a>
            )}    
        </div>

        <div className="row list-row">
          {this.renderForm()}
          {this.renderVideo()}
        </div>
        
      </div>
    );
  }
}

const mapStateToProps = ({data}) => {
  return {
    videos : data.videos,
    isSaving: data.isSaving,
    saveError: data.saveError
  }
}



export default connect(mapStateToProps, actions)(List);