import React, {Component} from 'react';
import List from './List';
import SideBar from './SideBar';
import NavBar from './NavBar';


class Home extends Component {
  render() {
    return (
      <>
        <NavBar />
        <div className="container-fluid">
          <div className="row">
          <SideBar/>
            <div className="col-md-9 ml-sm-auto col-lg-10 px-4">
              <List/>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Home;