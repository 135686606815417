import React, {Component} from 'react';
import {connect} from 'react-redux';
import { logout} from '../actions';

class NavBar extends Component {

  render() {
    const {logout} = this.props;
    return (
      <nav className="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow">
        <a className="navbar-brand col-sm-3 col-md-3 mr-0" href="#">WWEN</a>
        <ul className="navbar-nav px-3">
          <li className="nav-item text-nowrap">
            <a className="nav-link" href="#" onClick={()=> logout()}>Sign out</a>
          </li>
        </ul>
      </nav>  
    );
  }
}
export default connect(null, {logout})(NavBar);

