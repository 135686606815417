import React, {Component} from 'react';
import {connect} from 'react-redux';

class SideBar extends Component {
  

  render() {
    return (
      <div className="col-md-2 d-none d-md-block bg-light sidebar">
        <div className="sidebar-sticky">
        <ul className="nav flex-column">
          <li className="nav-item">
            <a className="nav-link active" href="#">
            <span className="fa fa-home"></span> Videos <span className="sr-only">(current)</span>
            </a>
          </li>
        
        </ul>
        </div>
      </div>    
    );
  }
}
export default connect(null, null)(SideBar);

