import React, {Component} from 'react';
import {connect} from 'react-redux';

import { BrowserRouter as Router, Route, Link } from 'react-router-dom'

class ListItem extends Component {
  render() {
    const{id, video, open} = this.props;
    const to = `/public/${video._id}`;
    return (

      <tr>
        <td>{id}</td>
        <td>{video && video.v_title}</td>
        <td>{video && (<a href={video.v_youtube}>{video.v_youtube}</a>)}</td>
        <td><Link to={to} >/{video._id}</Link></td>
        <td>{video && video.v_password}</td>
        <td> 
          <a onClick={() => open(true, video, video._id) } className="btn btn-secondary btn-sm"> <i className="fa fa-pencil"></i> </a>
        </td>            
      </tr> 
    );
  }
}
export default connect(null, {})(ListItem);